import React from "react";
import { TestimonialDiv } from "components/Cohort/styles";

const Testimonial = ({ title, quote, projectImgSrc, headshotImgSrc, name, occupation }) => {
  return (
    <TestimonialDiv>
      <div className="project">
        <img src={projectImgSrc} />
        <br />
        <small>
          <i>{title}</i>
        </small>
        <p>"{quote}"</p>
      </div>
      <div className="profile">
        <img height="50px" src={headshotImgSrc} />
        <p>{name}</p>
        <small>{occupation}</small>
      </div>
    </TestimonialDiv>
  );
};

export default Testimonial;
