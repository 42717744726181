import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import CallToAction from "components/Cohort/CallToAction";
import Testimonial from "components/Cohort/Testimonial";
import { motion, AnimatePresence } from "framer-motion";

import {
  Header,
  YouTubeContainer,
  Community,
  Value,
  Button,
  FAQContainer,
  Pricing,
  Message,
  TeamContainer,
  ProfileCircle,
  TimelineProjectsContainerLanding,
  Structure,
  QuickFacts,
  Title
} from "components/Cohort/styles";

import {
  BackgroundBar,
  ProgressBarContainer,
  Divider,
  ProjectDiv
} from "components/Cohort/Dashboard/styles";

import { auth } from "api/auth";

import { Row, Col } from "react-grid-system";

export default function Cohort() {
  const [testimonialState, setTestimonialState] = useState(0); // 0 for students, 1 for professionals
  const [showMoreClickedStudent, setShowMoreClickedStudent] = useState(false);
  const [
    showMoreClickedProfessional,
    setShowMoreClickedProfessional
  ] = useState(false);
  const [refId, setRefId] = useState(null);

  const greyedOut = {
    color: "#888"
  };

  useEffect(() => {
    if (
      process.env.NODE_ENV === "production" &&
      typeof window !== "undefined"
    ) {
      window.focus();
      var listener = window.addEventListener("blur", function() {
        if (document.activeElement === document.getElementById("video")) {
          trackClick("video click", "Cohort");
        }
        window.removeEventListener("blur", listener);
      });
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const user = urlParams.get("user");
    if (user && user != "") {
      setRefId(user);
    }
  }, []);

  // function trackClick(label, category) {
  //   if (process.env.NODE_ENV === "production") {
  //     typeof window !== "undefined" &&
  //       window.gtag("event", "click", {
  //         event_label: label,
  //         event_category: category
  //       });
  //   }
  // }

  return (
    <div className="mx-auto max-w-screen-xl">
      <Helmet
        title="Learn to code in two weeks with Enlight Cohorts"
        meta={[
          {
            name: "description",
            content:
              "Enlight Cohorts are two week programs that teach you to code the apps and websites you've always wanted to make — even if you've had no prior experience."
          },
          {
            name: "og:title",
            content: "Cohort | Enlight"
          },
          {
            name: "og:description",
            content:
              "Enlight Cohorts are two week programs that teach you to code the apps and websites you've always wanted to make — even if you've had no prior experience."
          },
          { name: "og:url", content: "https://enlight.nyc/cohort" },
          {
            name: "og:image",
            content: "https://enlight.nyc/img/cohort/cohort-social.png"
          },
          {
            name: "twitter:title",
            content: "Cohort | Enlight"
          },
          {
            name: "twitter:description",
            content:
              "Enlight Cohorts are two week programs that teach you to code the apps and websites you've always wanted to make — even if you've had no prior experience."
          },
          {
            name: "twitter:image",
            content: "https://enlight.nyc/img/cohort/cohort-social.png"
          }
        ]}
      />

      <Header>
        <main>
          <div className="titles">
            <div>
              <h1>Launch your coding journey.</h1>
              <h3>
                Learn to code in two weeks — no experience necessary. During
                this cohort, learn new technologies by building <i>real</i>{" "}
                projects.
              </h3>
              <br /> <br />
              <Button
                //onClick={() => trackClick("top apply button", "Cohort")}
                href={
                  refId
                    // ? `/cohort/apply/referral?user=${refId}`
                    ? `/cohort/interest/`
                    : "/cohort/interest/"
                }
                padding="12px"
              >
                <span>Join the waitlist</span>
              </Button>
            </div>
          </div>
          <div>
            <div className="pitch-video">
              Want to see how it's done? Watch this short video &#8250;
            </div>
            <YouTubeContainer>
              <iframe
                id="video"
                src="https://www.youtube.com/embed/HJ-0mqS3Iiw"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </YouTubeContainer>
          </div>
        </main>
      </Header>

      <QuickFacts>
        <section>
          {/* <div>
            <h2>Cohort starts</h2>
            <h3>August 13th</h3>
            <p>Join before spots run out.</p>
          </div> */}

          <div>
            <h2>Time</h2>
            <h3>2 week commitment</h3>
            <p>1-2 hrs/day for two weeks. Get immersed in the process.</p>
          </div>

          <div>
            <h2>Final product</h2>
            <h3>4 practical projects</h3>
            <p>Learn & build from 10 exclusive webinars and 35+ tutorials.</p>
          </div>

          <div>
            <h2>This cohort is for</h2>
            <h3>All levels</h3>
            <p>No experience neccessary. Level up your skills. </p>
          </div>
        </section>
      </QuickFacts>

      <Title>
        <p>An Immersive Learning Experience</p>
        <h2>Create 4 projects in 14 days</h2>
      </Title>

      <Value>
        <div >
          <img src="/img/cohort/undraw_dev_productivity_umsq.svg" />
          <h2>Learn by doing</h2>
          <p>
            We're not your typical online course or coding bootcamp. You'll
            complete four unique web development or data science projects of
            your interest by following our tutorials and webinars.
          </p>
        </div>

        <div>
          <img src="/img/cohort/undraw_project_completed_w0oq.png" />
          <h2>Stay accountable</h2>
          <p>
            You'll be building your portfolio alongside other learners in our
            community. Get inspired, ask for feedback, and ship your finished
            projects for everyone to see!
          </p>
        </div>

        <div>
          <img src="/img/cohort/undraw_friends_online_klj6.svg" />
          <h2>Supportive community</h2>
          <p>
            Cohorts are designed for your success — we're in this together from
            building your first personal website to presenting your capstone at
            demo day.
          </p>
        </div>
      </Value>

      <Title>
        <p>Two weeks. Perfectly structured.</p>
        <h2>
          Learn through building <i>real</i> projects
        </h2>
      </Title>

      <Structure>
        <div className="timeline">
          <ProgressBarContainer>
            <BackgroundBar>
              <Divider left="0%" />
              <Divider left="25%" />
              <Divider left="50%" />
              <Divider left="75%" />
              <Divider left="100%" />
            </BackgroundBar>
          </ProgressBarContainer>
          <TimelineProjectsContainerLanding>
            <ProjectDiv left="0%">
              <h3>Kickoff</h3>
              {/* <p>August 13th</p> */}
            </ProjectDiv>
            <ProjectDiv left="25%">
              <h3>Project 1</h3>
              {/* <p>August 15th</p> */}
            </ProjectDiv>
            <ProjectDiv left="50%">
              <h3>Project 2</h3>
              {/* <p>August 19th</p> */}
            </ProjectDiv>
            <ProjectDiv left="75%">
              <h3>Project 3</h3>
              {/* <p>August 23rd</p> */}
            </ProjectDiv>
            <ProjectDiv left="100%">
              <h3>Capstone</h3>
              {/* <p>August 27th</p> */}
            </ProjectDiv>
          </TimelineProjectsContainerLanding>
        </div>
        <div className="mobile-timeline">
          <div>
            <h3>Project 1</h3>
            {/* <p>August 15th</p> */}
          </div>
          <div>
            <h3>Project 2</h3>
            {/* <p>August 19th</p> */}
          </div>
          <div>
            <h3>Project 3</h3>
            {/* <p>August 23rd</p> */}
          </div>
          <div>
            <h3>Capstone</h3>
            {/* <p>August 27th</p> */}
          </div>
          <br />
        </div>

        <h4>Focus on what you are interested in...</h4>

        <article>
          <span>Web Development</span>
          <span>HTML</span>
          <span>CSS</span>
          <span>JavaScript</span>
          <span>Data Science</span>
          <span>Python</span>
          <span>Flask</span>
          <span>React.js</span>
          <span>Node.js</span>
          <span>APIs</span>
          <span>Web Scraping</span>
          <span>Machine Learning</span>
        </article>

        <h4>and unleash your potential.</h4>
        <object>
          <Button
            //onClick={() => trackClick("potenial apply button", "Cohort")}
            // href={
            //   refId
            //     ? `/cohort/apply/referral?user=${refId}`
            //     : "/cohort/apply/learner"
            // }
            href={
              "/cohort/interest"
            }
            padding="12px"
          >
            <span>Join the waitlist</span>
          </Button>
        </object>
      </Structure>

      <Title>
        <p>Don't just take our word for it</p>
        <h2>Hear from our past cohort members</h2>
      </Title>
      <Community>
        <div id="controls">
          <h3
            onClick={() => {
              setTestimonialState(0);
            }}
            style={testimonialState ? greyedOut : null}
          >
            Students
          </h3>{" "}
          <h3
            onClick={() => {
              setTestimonialState(1);
            }}
            style={testimonialState ? null : greyedOut}
          >
            Professionals
          </h3>
        </div>

        <AnimatePresence exitBeforeEnter>
          {testimonialState ? (
            <motion.div
              key="professionals"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <section>
                <Testimonial
                  title="Aashish's React Personal Dashboard"
                  projectImgSrc="/img/cohort/aashish.png"
                  headshotImgSrc="/img/cohort/aashish_headshot.png"
                  quote="During the cohort, the 'learning by doing' methodology came to life — 
                  this cohort experience constantly pushed me to put learning to practice."
                  name="Aashish"
                  occupation="Data and Tech Strategist"
                />
                <Testimonial
                  title="Divya's Paint App"
                  projectImgSrc="/img/cohort/divya.png"
                  headshotImgSrc="/img/cohort/divya_headshot.png"
                  quote="My experience with the cohort provided me with a tangible skillset 
                  which strengthened my ability to succeed in my current profession and 
                  advance my career."
                  name="Divya"
                  occupation="Product Manager"
                />
                <Testimonial
                  title="Ilan's To Do List"
                  projectImgSrc="/img/cohort/ilan.png"
                  headshotImgSrc="/img/cohort/ilan_headshot.png"
                  quote="Enlight allows you to feel a 
                  sense of accomplishment that you were able to create something on your own."
                  name="Ilan"
                  occupation="Product Manager"
                />
              </section>

              {showMoreClickedProfessional ? null : (
                <button onClick={() => setShowMoreClickedProfessional(true)}>
                  Show More
                </button>
              )}
              {showMoreClickedProfessional ? (
                <>
                  <section>
                    <Testimonial
                      title="Umar's To Do List"
                      projectImgSrc="/img/cohort/umar.png"
                      headshotImgSrc="/img/cohort/umar_headshot.png"
                      quote="Enlight makes learning rather easy and gives me the confidence to build 
                  real world projects."
                      name="Umar"
                      occupation="MBA, Product Manager"
                    />
                    <Testimonial
                      title="Snehal's Personal Website"
                      projectImgSrc="/img/cohort/snehal.png"
                      headshotImgSrc="/img/cohort/snehal_headshot.png"
                      quote="Having absolutely no experience or knowledge in web development, 
                  I started from 0. By the end of just 14 days, I had 4 projects to show off 
                  which I am super proud of."
                      name="Snehal"
                      occupation="Consultant"
                    />
                    <Testimonial
                      title="Abilash's Web Scraper"
                      projectImgSrc="/img/cohort/abilash.png"
                      headshotImgSrc="/img/cohort/abilash_headshot.png"
                      quote="Cohorts really makes a difference in motivating you by providing the toolkit for success. 
                  I hope to continue to be associated with this initiative in the spirit 
                  of continuous learning."
                      name="Abilash"
                      occupation="Product Manager"
                    />
                  </section>
                </>
              ) : null}
            </motion.div>
          ) : (
            <motion.div
              key="students"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <section>
                <Testimonial
                  title="Neha's Flask To-Do List App"
                  projectImgSrc="/img/cohort/nori.png"
                  headshotImgSrc="/img/cohort/nori_headshot.png"
                  quote="Through this experience, I was able to understand that I was not behind, but rather that I had never been 
                  exposed to coding concepts in a hands-on environment."
                  name="Neha"
                  occupation="University of Michigan"
                />
                <Testimonial
                  title="Shashwat's Instagram Marketing Page"
                  projectImgSrc="/img/cohort/shashwat.png"
                  headshotImgSrc="/img/cohort/shashwat_headshot.jpg"
                  quote="I was learning to code through YouTube
                and freeCodeCamp, but I was able to learn
                more quickly and thoroughly by making projects from scratch with Enlight."
                  name="Shashwat"
                  occupation="Purdue University"
                />
                <Testimonial
                  title="Jacquelyn's React Weather App"
                  projectImgSrc="/img/cohort/jacquelyn.png"
                  headshotImgSrc="/img/cohort/jacquelyn_headshot.png"
                  quote="Previously, I didn't build my own projects because I lacked 
                  confidence and didn't know where to start. Through learning with 
                  Enlight, I've built up more passion to create side projects of my own."
                  name="Jacquelyn"
                  occupation="Swarthmore University"
                />
              </section>

              {showMoreClickedStudent ? null : (
                <button onClick={() => setShowMoreClickedStudent(true)}>
                  Show More
                </button>
              )}
              {showMoreClickedStudent ? (
                <>
                  <section>
                    <Testimonial
                      title="Erin's To-Do List"
                      projectImgSrc="/img/cohort/erin.png"
                      headshotImgSrc="/img/cohort/erin_headshot.png"
                      quote="There's always someone in 
                  the community willing to help answer questions, debug, or 
                  just appreciate what I'm working on."
                      name="Erin"
                      occupation="Georgia Institute of Technology"
                    />
                    <Testimonial
                      title="Jonathan's Chat App"
                      projectImgSrc="/img/cohort/jon.png"
                      headshotImgSrc="/img/cohort/jon_headshot.png"
                      quote="Enlight's mantra of 'coding by doing' was the most engaging way 
                  to learn to code for me and connect with other learners that share 
                  a passion to code!"
                      name="Jonathan"
                      occupation="University of Michigan"
                    />
                    <Testimonial
                      title="Turja's Portfolio Website"
                      projectImgSrc="/img/cohort/turja.png"
                      headshotImgSrc="/img/cohort/turja_headshot.png"
                      quote="Enlight gave me the opportunity to 
                  learn at a good pace and establish the fundamental skills to 
                  build the projects I was interested in."
                      name="Turja"
                      occupation="Ryerson University"
                    />
                  </section>
                </>
              ) : null}
              {/* <section>
              <Testimonial
                  title="Grant's Personal Dashboard"
                  projectImgSrc="/img/cohort/grant.png"
                  headshotImgSrc="/img/cohort/grant_headshot.png"
                  quote="Taking computer science classes, there is a heavy emphasis on
            theory rather than practicality and I really wanted to fill that
            gap but didn't know where to start. Enlight was the perfect
            opportunity."
                  name="Grant Ho"
                  occupation="University of Michigan"
                />
                <Testimonial
                  title="Jenna's Geolocation Weather App"
                  projectImgSrc="/img/cohort/jenna.png"
                  headshotImgSrc="/img/cohort/jenna_headshot.jpg"
                  quote="Through the cohort, I could pick my own projects and expand
            beyond the tutorials to add my personal touch."
                  name="Jenna Jaehnig"
                  occupation="Student"
                />
                
                <Testimonial
                  title="Chris's Live Code Editor"
                  projectImgSrc="/img/cohort/chris.png"
                  headshotImgSrc="/img/cohort/chris_headshot.png"
                  quote="Enlight has definitely given me the confidence to code more 
                  effectively than learning on my own. I would recommend Cohorts to 
                  anyone looking to focus on learning specific skillsets in a 
                  project-based way."
                  name="Chris Valencia"
                  occupation="University of Pennsylvania"
                />
                
                
              </section> */}
            </motion.div>
          )}
        </AnimatePresence>
      </Community>
{/* 
      <Pricing>
        <br />
        <h1>Ready to level up in 14 days?</h1>
        <div>
          <h2>Enlight {refId ? "Referral" : "Learner"}</h2>
          <h4>Cohort #3: August 13th - August 27th</h4> */}

          {/* <p className="price">
            {" "}
            <span className="compare">$100</span>{" "}
            <span className="cost">${refId ? "35" : "50"}</span>{" "}
            <span className="tag">{refId ? "save 65%" : "save 50%"}</span>
          </p> */}

          {/* <ul>
            <li>
              Build 4 <i>real</i> projects
            </li>
            <li>10 project-based webinars</li>
            <li>Exclusive community & forum</li>
            <li>Project recommendations</li>
            <li>Certificate of completion</li>
          </ul>

          <br />
          <Button
            onClick={() => trackClick("price apply button", "Cohort")}
            href={
              refId
                ? `/cohort/apply/referral?user=${refId}`
                : "/cohort/apply/learner"
            }
            padding="12px"
          >
            <span>Join now</span>
          </Button>
          <br />
          <br />
          <br />
          <i>Applications close August 13th @ 2PM EST. Limited spots — act soon.</i>
        </div> */}

        {/* <p className="student">
          Are you a student?{" "}
          <a
            onClick={() => trackClick("student text", "Cohort")}
            href="mailto:team@enlight.nyc?subject=Enlight Student Scholarship Interest"
          >
            {" "}
            Contact us{" "}
          </a>
          about our student scholarship.
        </p> */}
      {/* </Pricing> */}
      <br />

      {/* <Message>
        <h2>Don't take an online course — join an Enlight Cohort instead.</h2>
        <section>
          <li>
            {" "}
            <img height="35px" src="/img/cohort/checkbox.png" />{" "}
            <p>Build & deploy a blazing-fast website ⚡</p>
          </li>
          <li>
            {" "}
            <img height="35px" src="/img/cohort/checkbox.png" />{" "}
            <p>Train ML models & analyze data 🤖</p>
          </li>
          <li>
            {" "}
            <img height="35px" src="/img/cohort/checkbox.png" />{" "}
            <p>Understand GitHub & version control 💻</p>
          </li>
          <li>
            {" "}
            <img height="35px" src="/img/cohort/checkbox.png" />{" "}
            <p>Complete four practical portfolio projects 💼</p>
          </li>
          <li>
            {" "}
            <img height="35px" src="/img/cohort/checkbox.png" />{" "}
            <p>Join a supportive community 👋</p>
          </li>
          <li>
            {" "}
            <img height="35px" src="/img/cohort/checkbox.png" />{" "}
            <p>Stay motivated & accountable 💪</p>
          </li>
        </section>

        <object>
          <Button
            onClick={() => trackClick("price apply button", "Cohort")}
            href={
              refId
                ? `/cohort/apply/referral?user=${refId}`
                : "/cohort/apply/learner"
            }
            padding="12px"
          >
            <span>Join now</span>
          </Button>
        </object>
      </Message> */}
      {/* <Message>
        <h2>Ever wanted to learn...</h2>
        <br />

        <article>
          <span>Web Development</span>
          <span>HTML</span>
          <span>CSS</span>
          <span>JavaScript</span>
          <span>Data Science</span>
          <span>Python</span>
          <span>Flask</span>
          <span>React.js</span>
          <span>Node.js</span>
          <span>APIs</span>
          <span>Web Scraping</span>
          <span>Machine Learning</span>
        </article>
        <br />
        <h2>but didn't know where to start?</h2>
        <object>
          <b>—</b>
        </object>
        <br />
        <h2>
          Learn what{" "}
          <i>
            <u>you</u>
          </i>{" "}
          are interested in by building projects. <br />
        </h2>
        <h4>
          <i>No prior experience neccessary. </i>
        </h4>
      </Message> */}

      <FAQContainer>
        <h1>FAQ</h1>
        <Row>
          <Col lg={3} sm={12}>
            <h3>Who is this program for?</h3>
            <p>
              If you've always thought about learning to code but were
              overwhelmed by where to start, this program is meant for you. No
              experience is needed to start learning how to build real projects.
            </p>
          </Col>
          <Col lg={3} sm={12}>
            <h3>Why did we create this?</h3>
            <p>
              We wanted to create the program that would have helped us
              tremendously when we were learning to code — so that's exactly
              what we did.
            </p>
          </Col>
          <Col lg={3} sm={12}>
            <h3>What will I learn?</h3>
            <p>
              You will learn the fundamentals of web development and/or data
              science by building four unique projects, including your own
              capstone. The first three projects are projects from Enlight.
            </p>
          </Col>

          <Col lg={3} sm={12}>
            <h3>Do you offer any scholarship opportunities?</h3>
            <p>
              Yes — if interested, please
              <a href="mailto:team@enlight.nyc?subject=Enlight Scholarship Interest">
                {" "}
                email
              </a>{" "}
              and let us know why you're interested! Spots for this opportunity
              are offered on a case by case basis.
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg={3} sm={12}>
            <h3>What's the estimated time commitment?</h3>
            <p>
              This is entirely up to you, but we strongly reccomend setting
              aside 1-2 hours per day for this program to get the most out of
              it! You will complete projects every 3-4 days.
            </p>
          </Col>

          <Col lg={3} sm={12}>
            <h3>What do I need to join?</h3>
            <p>
              All you need is a computer and a drive to learn. The program is
              entirely online and powered by the Enlight platform, Zoom, &
              Discord.
            </p>
          </Col>
          <Col lg={3} sm={12}>
            <h3>When are the events?</h3>
            <p>
              {/* Check out our{" "}
              <a href="https://calendar.google.com/calendar?cid=bXQwa2hsb3BobG5rYzdsMzdxZnVic2hoa2tAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ">
                calendar
              </a>{" "}
              to see the full list of events.  */}
              All webinars are in the evenings. They are not mandatory to attend
              and recordings are available. The only two mandatory events are
              kickoff and demo day, which are at 8:30 PM EDT. Check out our{" "}
              <a href="https://calendar.google.com/calendar/embed?src=nbutf333ihrnecup8bfp0u1l6g%40group.calendar.google.com&ctz=America%2FNew_York">
                calendar
              </a>{" "}
              to see a sample list of events.
            </p>
          </Col>
          <Col lg={3} sm={12}>
            <h3>Wait, I have another question!</h3>
            <p>
              Please reach out to us either at{" "}
              <a href="mailto:team@enlight.nyc">team@enlight.nyc</a> or on our{" "}
              <a href="https://discord.gg/8FwTpfN">Discord </a>
              community. We'd be happy to answer any question you have.
            </p>
          </Col>
        </Row>
      </FAQContainer>

      <TeamContainer>
        <h1>Meet your instructors</h1>
        <Row>
          <Col lg={2} offset={{ lg: 3 }} sm={4}>
            <ProfileCircle src="/img/cohort/samay_headshot.jpg" />
            <h4>Samay Shamdasani</h4>
          </Col>
          <Col lg={2} sm={4}>
            <ProfileCircle src="/img/cohort/maxim_headshot.jpg" />
            <h4>Maxim Geller</h4>
          </Col>
          <Col lg={2} sm={4}>
            <ProfileCircle src="/img/cohort/varun_headshot1.jpg" />
            <h4>Varun Jindal</h4>
          </Col>
        </Row>
        <h3>Community Mentors</h3>
        <Row>
          <Col sm={3}>
            <ProfileCircle src="/img/cohort/oliver_headshot.jpg" />
            <h4>Oliver</h4>
          </Col>
          <Col sm={3}>
            <ProfileCircle src="/img/cohort/sam_headshot.jpg" />
            <h4>Sam</h4>
          </Col>
          <Col sm={3}>
            <ProfileCircle src="/img/cohort/yaechan_headshot.jpg" />
            <h4>Yaechan</h4>
          </Col>
          <Col sm={3}>
            <ProfileCircle src="/img/cohort/celene_headshot.jpg" />
            <h4>Celene</h4>
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <ProfileCircle src="/img/cohort/nisreen_headshot.jpg" />
            <h4>Nisreen</h4>
          </Col>
          <Col sm={3}>
            <ProfileCircle src="/img/cohort/erica_headshot.jpg" />
            <h4>Erica</h4>
          </Col>
          <Col sm={3}>
            <ProfileCircle src="/img/cohort/lawrence-headshot.png" />
            <h4>Lawrence</h4>
          </Col>
          <Col sm={3}>
            <ProfileCircle src="/img/cohort/liv_headshot.jpg" />
            <h4>Olivia</h4>
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <ProfileCircle src="/img/cohort/erin_headshot.png" />
            <h4>Erin</h4>
          </Col>
          <Col sm={3}>
            <ProfileCircle src="/img/cohort/divya_headshot.png" />
            <h4>Divya</h4>
          </Col>
          <Col sm={3}>
            <ProfileCircle src="/img/cohort/nori_headshot.png" />
            <h4>Nori</h4>
          </Col>
          <Col sm={3}>
            <ProfileCircle src="/img/cohort/jacquelyn_headshot.png" />
            <h4>Jacquelyn</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              Our team is very experienced in web development (HTML, CSS, JS,
              React.js, Flask) and data science (Python, Seaborn, Pandas,
              Scikit). Our mentors have just gone through the cohort themselves
              — giving them the unique perspective of a once beginner.
            </p>
          </Col>
        </Row>
      </TeamContainer>

      <CallToAction refId={refId} />
    </div>
  );
}
