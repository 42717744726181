import React from "react";
import { CTAContainer, ApplyButton } from "components/Cohort/styles";

const verbList = [
  "Build?",
  "Learn?",
  "Create?",
  "Code?",
  "Explore?",
  "Innovate?",
  "Ship?",
  "Hustle?"
];

class CallToAction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      verb: "",
      listIndex: 0,
      wordIndex: 0,
      typing: true,
      refId: this.props.refId
    };
  }
  componentDidMount() {
    this.type();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.refId !== prevProps.refId) {
      this.setState({
        refId: this.props.refId
      })
    }
  }

  type = () => {
    if (
      this.state.wordIndex < verbList[this.state.listIndex].length &&
      this.state.typing
    ) {
      this.setState({
        verb:
          this.state.verb + verbList[this.state.listIndex][this.state.wordIndex]
      });
      let newWordIndex = this.state.wordIndex + 1;
      this.setState({
        wordIndex: newWordIndex
      });
      setTimeout(this.type, 100);
    } else if (
      this.state.wordIndex == verbList[this.state.listIndex].length &&
      this.state.typing
    ) {
      this.setState({
        typing: false
      });
      setTimeout(this.type, 2000);
    } else if (this.state.wordIndex >= 0) {
      this.setState({
        verb: this.state.verb.slice(0, this.state.wordIndex),
        wordIndex: this.state.wordIndex - 1
      });
      setTimeout(this.type, 75);
    } else {
      this.setState({
        typing: true,
        wordIndex: 0,
        listIndex: (this.state.listIndex + 1) % 5
      });
      setTimeout(this.type, 20);
    }
  };

  render() {
    // function ctaClick() {
    //   if (process.env.NODE_ENV === "production") {
    //     typeof window !== "undefined" &&
    //       window.gtag("event", "click", {
    //         event_label: "cta button",
    //         event_category: "Cohort"
    //       });
    //   }
    // }
    return (
      <CTAContainer>
        <div>
          <p>
            Ready to <span>{this.state.verb}</span>
          </p>
        </div>{" "}
        <ApplyButton
          // onClick={() => ctaClick()}
          // href={this.state.refId ? `/cohort/apply/referral?user=${this.state.refId}` : "/cohort/apply/learner"}
          href={"/cohort/interest"}
          padding="12px"
        >
          Join the waitlist
        </ApplyButton>
      </CTAContainer>
    );
  }
}

export default CallToAction;
