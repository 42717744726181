import styled, { keyframes } from "styled-components";

export const BackgroundBar = styled.div`
  background-color: #f5f5f5;
  width: 100%;
  height: 20px;
  position: relative;
  div {
    opacity: 1;
  }
`;

export const Button = styled.a`
  display: flex;
  width: 60%;
  vertical-align: center;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 20px;
  padding: 10px;
  font-size: 18px;
  border: 3px solid #438cee;
  color: #444;
  border-radius: 5px;
  font-weight: bold;
  background-color: #fff;

  &:hover {
    background: #438cee;
    border: 3px solid #438cee;
    color: #fafafa;
    transition: all 300ms ease;
    cursor: pointer;
  }
`;

export const Divider = styled.div`
  width: 2px;
  height: 20px;
  position: absolute;
  display: inline-block;
  background-color: #c0c0c0;
  opacity: 1;
  z-index: 2;
  top: 0;
  left: ${props => props.left};
`;

export const ProjectDiv = styled.div`
  position: absolute;
  left: ${props => props.left};
  top: 0;
  transform: translateX(-50%);

  h3 {
    color: #333;
    font-size: 16px;
  }

  p {
    color: #474747;
  }

  section {
    display: block;
    width: 275px !important;
  }

  section .submit {
    padding: 8px;
    font-size: 16px;
    background-color: #f4f4f4;
    border-radius: 6px;
    font-weight: bold;
    color: #333;
    cursor: pointer;
    transition: all 300ms ease;
    &:hover {
      opacity: 0.8;
    }
  }

  p {
    margin: 8px;
  }

  @media (max-width: 1200px) {
    display: block;
    position: static;
    margin: 0 auto;
    transform: translateX(0px);
    margin-top: 40px;
    margin-bottom: 40px;
  }
`;

// to change widths, change that of ProgressBarContainers & TimelineProjectsContainer

export const ProgressBarContainer = styled.div`
  display: flex;
  margin: auto;
  padding: 16px 0px;
  max-width: 85%;
  @media (max-width: 1200px) {
    display: none;
  }
`;

export const TimelineProjectsContainer = styled.div`
  width: 80%;
  margin: auto;
  text-align: center;
  position: relative;
  height: 500px;

  @media (max-width: 1200px) {
    display: block;
    position: static;
    height: auto;
  }
`;

export const ProjectSection = styled.section`
  margin: 0 auto;
  #finishButton {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

export const Select = styled.select`
  font-size: 14px;
  width: 200px;
  padding: 4px;
  margin: 4px 0px;
  margin-right: 0px;
  box-sizing: border-box;
  margin-left: 0px;
  color: #474747;
  border: 2px solid #e4e4e4;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 5px;
  transition: all 300ms ease hover;
  &:focus {
    border: 2px solid #438cee;
  }
`;

export const Questions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  div {
    width: 200px;
    margin: 5px 10px;
  }
  @media only screen and (max-width: 1024px) {
    div {
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
    display: block;
  }
`;

export const Card = styled.div`
  height: 300px;
  margin: 20px;
  border-radius: 4px;
  background: #fcfcfc;
  -moz-box-shadow: 0 0.2rem 3rem rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.08);
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.08);
  transition: all 200ms ease;
  position: relative;

  &:active,
  &:focus,
  &:hover {
    -moz-box-shadow: 0 0.2rem 3rem rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.2);
    box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.2);
  }
`;

export const Title = styled.h3`
  margin-top: 24px;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: #191919;
  margin-left: 8px;
  margin-right: 8px;
  @media only screen and (max-width: 1024px) {
    margin-top: 5px;
  }
`;

export const Left = styled.div`
  position: absolute;
  left: 12px;
  top: 260px;
  padding: 8px;
  color: #ffffff;
  background: #191919;
  font-size: 10px;
  font-weight: bold;
  border-radius: 4px;
`;

export const Right = styled.span`
  position: absolute;
  right: 12px;
  top: 260px;
  padding: 8px;
  color: #ffffff;
  background: #438cee;
  font-size: 10px;
  font-weight: bold;
  border-radius: 4px;
  text-transform: uppercase;
  letter-spacing: 0px;
`;

export const Img = styled.div`
  background-image: url(${props => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 175px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

export const EventList = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  margin: 16px;

  h2 {
    color: #333;
    text-align: center;
    margin: 16px 0px;
  }
`;

export const Event = styled.li`
  color: #474747;
  display: flex;
  flex-direction: column;
  margin: 16px 8px;
  width: 100%;
  font-size: 18px;

  p {
    color: #474747;
    margin: 8px;
    font-size: 14px;
  }

  span {
    color: #333;
    padding: 8px;
    border-radius: 8px;
    text-transform: uppercase;
    display: inline-block;
    letter-spacing: 1px;
    font-size: 12px;
    font-weight: bold;
  }

  .live {
    color: #c54245;
    text-transform: uppercase;
    display: inline-block;
    letter-spacing: 1px;
    font-size: 14px;
    margin: 0;
    padding: 8px;
    border-radius: 8px;
  }
`;

export const EventContainer = styled.div`
  display: block;
  margin: 16px auto;
  padding: 0;
`;

export const Recommendations = styled.div`
  max-width: 60em;
  margin: auto;
  padding: 16px;
  text-align: center;

  h2 {
    color: #333;
    text-align: center;
    margin: 16px 0px;
  }

  label {
    font-size: 14px;
    color: #333;
    text-align: none;
    padding: 8px 0px;
  }
`;

export const MakerLog = styled.div`
  h2 {
    color: #333;
    text-align: center;
    margin: 24px 0px;
  }
`;

export const HeaderContainer = styled.div`
  margin: 24px;

  h1 {
    color: #333;
    text-align: center;
    margin: 12px 0px;
    font-size: 32px;
  }

  h1 span {
    background: #f4fbff;
    color: #2f80ed;
    display: inline-block;
    margin: 0;
    padding: 8px;
    border-radius: 8px;
  }

  main {
    display: flex;
    align-items: center;
  }

  img {
    height: 50px;
    border-radius: 50%;
  }

  h2 {
    color: #333;
    margin: 8px;
  }

  .streak {
    background: #f4fbff;
    color: #2f80ed;
    display: inline-block;
    margin: 0;
    padding: 4px;
    border-radius: 8px;
  }
`;

export const ResourcesContainer = styled.div`
  margin: 16px auto;
  text-align: left;
  display: flex;
  justify-content: center;
  @media (max-width: 1023px) {
    display: block;
  }
`;

export const ResourcesList = styled.ul`
  list-style: none;
  padding: 16px;
  margin: 0 auto;
  text-align: left;
  display: block;
  justify-content: center;

  h2 {
    color: #333;
    padding: 16px 24px;
  }

  @media (max-width: 1023px) {
    text-align: center;
  }
`;

export const Resource = styled.li`
  margin: 8px;
  transition: all 300ms ease;
  font-size: 24px;

  &:hover a {
    opacity: 0.6;
  }

  @media (min-width: 1023px) {
    h2 {
      padding-left: 0px;
      padding-right: 54px;
    }
  }

  p {
    color: #d3d3d3;
  }
`;

export const Share = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 16px 0px;

  a {
    margin: 8px;
  }
`;

export const Container = styled.div`
  margin: 16px 0px;
`;

export const Form = styled.div`
  padding: 16px;
  margin: 0 auto;
  max-width: 70em;
  -moz-box-shadow: 0 0 15px #e4e4e4;
  -webkit-box-shadow: 0 0 15px #e4e4e4;
  box-shadow: 0 0 15px #e4e4e4;
  border-radius: 4px;
  background: linear-gradient(to right, #56ccf2, #82b2f4);
  color: #333;
`;

export const Input = styled.input`
  font-size: 18px;
  display: block;
  border-radius: 4px;
  padding: 4px;
  color: white;
  border: 2px solid #e4e4e4;
  outline: none;
  background: transparent;
  width: 95%;
`;

export const Label = styled.p`
  margin-top: 16px;
  margin-bottom: 8px;
`;

export const IBTButton = styled.button`
  cursor: pointer;
  border-radius: 4px;
  font-size: 24px;
  padding: 6px;
  background: transparent;
  border: 2px solid #e4e4e4;
  transition: all 300ms ease;
  outline: none;
  &:hover {
    opacity: 0.5;
  }
`;

export const ReferralBanner = styled.section`
  margin: 48px 0px;

  div {
    margin: auto;
    max-width: 50em;
    border-radius: 8px;
    background-color: #438cee;
    padding: 32px 48px;
    -moz-box-shadow: 0 0.2rem 3rem rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.08);
    box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.08);
  }

  h1,
  p,
  span,
  small {
    color: #fafafa;
  }

  h1 {
    font-size: 36px;
  }

  p {
    margin: 16px 0px;
    font-size: 24px;
  }

  small {
    font-size: 18px;
    margin: 16px 0px;
  }

  @media (max-width: 768px) {
    padding: 0;
    div {
      padding: 16px;
    }
  }
`;

export const CopiedMessage = styled.div`
  border-radius: 8px;
  padding: 18px;
  background-color: #333;
  color: #fafafa;
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  text-align: center;
`;

export const CopyArea = styled.textarea`
  display: flex;
  border: none;
  border-radius: 6px;
  background-color: #fdfdfd;
  color: #333;
  resize: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-align: center;
  height: auto;
  padding-top: 16px;
  padding-bottom: 0;
  margin-top: 16px;
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  &:hover {
    background-color: #f1f1f1;
  }

  @media (max-width: 768px) {
    width: 90%;
    font-size: 12px;
  }

  @media (min-width: 768px) {
    width: 350px;
  }
  overflow: hidden;
`;
